@tailwind base;
@tailwind components;
@tailwind utilities;

.w-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.w-1350 {
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
}
.display-flex {
  display: flex;
}
@keyframes top_buttom_float {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 8px);
  } /* 下浮高度 */
}
img,
video {
  max-width: 100%;
}
.home {
  .pc-header-container {
    width: 100%;
    padding: 23px 0px;
    position: fixed;
    top: 0;
    z-index: 999;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    .pc-header {
      display: flex;
      justify-content: space-between;
      max-width: 1392px;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0px 18px;
      display: flex;
      color: #202020;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
    }
    .pc-header .logoIcon {
      width: 60px;
      height: 60px;
      margin-right: 66px;
    }
    .pc-header .nav {
      display: flex;
      align-items: center;
      /* padding-top: 30px; */
    }
    .pc-header .nav-item {
      margin-left: 40px;
      cursor: pointer;

      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px;
    }
    .pc-header .dashboard {
      height: 46px;
      line-height: 46px;
      padding: 0 25px;
      border-radius: 8px;
      margin-top: 17px;
      border: 2px solid #202020;
      margin-left: 47px;
      border-radius: 8px;
      border: 2px solid #202020;
      cursor: pointer;
    }
  }
  /* .pc-banner_bg {
    background: rgb(210, 236, 248);
  } */
  .pc-banner {
    max-width: 1920px;
    height: 935px;
    color: #000;
    padding-top: 106px;
    text-align: center;
    background-image: url("./assets/image/bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    @media (max-width: 1440px) {
      background-size: cover;
    }
  }
  .pc-banner-tips1 {
    font-size: 36px;
    line-height: 36px;
    margin: 57px auto 40px auto;
    color: #fff;
  }
  .pc-banner-tips2 {
    font-size: 74px;
    color: #fff;
    text-shadow: 0px 3px 17.1px #fff;
    font-family: "Inter";
    /* font-size: 6.16667rem; */
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @media (max-width: 1440px) {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1px 8px #fff;
      font-family: "Inter";
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
  }
  .pc-banner-bottom {
    display: flex;
    color: #fff;
    font-size: 28px;
    justify-content: center;
    margin-top: 40px;
  }
  .pc-banner-bottom .box {
    max-width: 344px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17.2px 33px;
    border-radius: 10px;
    border: 2px solid #000;
    background: #fbc60c;
    cursor: pointer;
    margin: 0px 90px;
    color: #000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @media (max-width: 1440px) {
      margin: 0px 8px;
    }
  }
  .pc-banner-bottom svg {
    width: 30px;
    height: 30px;
    margin-left: 20px;
  }
  .brand {
    background: #232323;
    padding: 136px 0px;
  }
  .brand-box {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .brand-li {
    /* width: 290px; */
    height: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 34.25px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .brand-li-img-1 {
    height: 54px;
  }
  .brand-li-img-2 {
    height: 54px;
  }
  .about {
    background: #000;
  }
  .about-box {
    display: flex;
    position: relative;
    padding-top: 230px;
    padding-bottom: 230px;
    align-items: center;
    @media (max-width: 1440px) {
      padding: 50px 18px;
    }
  }
  .about-bg {
    width: 607px;
    height: 596px;
    background-image: url("./assets/image/icon03.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .about-right {
    /* width: 50%; */

    color: #fff;
    padding: 0px 0px 0px 45px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
  }
  .about-us {
    /* font-size: 50px; */
    margin-bottom: 62px;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 64px;
    font-style: normal;

    font-weight: 800;
    line-height: 64px;
    @media (max-width: 1023px) {
      line-height: 24px;
    }
  }
  .about-text {
    width: 548px;
    font-size: 22px;
    margin-top: 38px;
    line-height: normal;
    color: #a5a5a5;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .about-icon {
    width: 76px;
    height: 104px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .about-whitelist {
    padding-top: 110px;
    padding-bottom: 135px;
    box-sizing: border-box;
    background-image: url("./assets/image/icon32.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 1440px) {
      padding: 50px 18px;
    }
  }
  .about-mask {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 33;
    background: rgba(0, 0, 0, 0.5);
  }
  .about-mask-content {
    width: 540px;
    color: #fff;
    padding: 40px;
    box-sizing: border-box;
    margin: 10% auto 0 auto;
    border-radius: 30px;
    background: #131d29;
    box-shadow: 0px 13px 6.7px 0px rgba(0, 0, 0, 0.25);
  }
  .about-mask-content-title {
    text-align: center;
    font-family: "Inter";
    font-size: 26px;
    position: relative;
  }
  .about-mask-content-title svg {
    position: absolute;
    top: -10px;
    right: 0px;
    cursor: pointer;
  }
  .about-mask-content-from {
  }
  .about-mask-content-from .item {
    color: #fff;
    margin-top: 40px;
  }
  .about-mask-content-from .item .label {
    font-size: 24px;
    font-family: "Inter";
    padding-bottom: 24px;
  }
  .about-mask-content-from .item input {
    width: 100%;
    height: 66px;
    font-size: 24px;
    color: #fff;
    padding: 0px 10px;
    border-radius: 64px;
    background: #2b343e;
    border: none;
    box-sizing: border-box;
  }
  .about-mask-content-from .tips {
    padding-top: 40px;
  }
  .about-mask-content-from .text {
    font-size: 18px;
    padding-bottom: 14px;
  }
  .about-mask-content-from .other {
    display: flex;
  }
  .about-mask-content-from .other svg {
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-right: 16px;
  }
  .about-mask-content-from .confirm {
    width: 340px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    background: #fff;
    color: #000;
    font-family: "Inter";
    font-size: 20px;
    cursor: pointer;
    margin: 40px auto 0px auto;
  }
  .whitelist-title {
    color: #fff;
    text-align: center;
    font-size: 50px;
    padding-bottom: 50px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .whitelist-text {
    margin: auto;
    max-width: 839px;
    color: #fff;
    font-size: 22px;
    text-align: center;
  }
  .whitelist-main {
    margin: 34px 0px;
  }
  .whitelist-main-left,
  .whitelist-main-right {
    flex: 1;
    padding: 30px 30px 30px 48px;

    border-radius: 30px;
    border: 2px solid #fff;
    background: #262626;
    box-shadow: 0px 13px 6.7px 0px rgba(0, 0, 0, 0.25);
  }
  .whitelist-main-left {
    margin-right: 20px;
  }
  .whitelist-main-right {
    margin-left: 20px;
  }
  .whitelist-main-left .title,
  .whitelist-main-right .title {
    font-size: 24px;
    color: #fff;
    text-align: center;
  }
  .whitelist-main-left .sub,
  .whitelist-main-right .sub {
    text-indent: -18px;
    font-size: 18px;
    padding-top: 24px;
    color: rgba(255, 255, 255, 0.6);
  }
  .whitelist-address {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    padding: 30px;
    border-radius: 30px;
    background: #262626;
    box-shadow: 0px 13px 6.7px 0px rgba(0, 0, 0, 0.25);
    .whitelist-address-left {
      display: flex;
      align-items: center;
    }
    .processBox {
      display: flex;
      align-items: center;
    }
  }

  .whitelist-address svg {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
  }
  .whitelist-buttom {
    width: 340px;
    height: 82px;
    margin: 50px auto 0px auto;
    border-radius: 10px;
    border: 2px solid #000;
    background: #fbc60c;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .whitelist-buttom svg {
    margin-left: 20px;
  }
  .whitelist-address-process {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      line-height: 22px;
    }
  }
  .processBox {
    flex: 1;
    border-radius: 30px;
    background: #454545;
    height: 16px;
    margin: 0px 14px;
  }
  .processBox .processBoxDiv {
    border-radius: 30px;
    height: 16px;
    background: #fbc60c;
  }
  .vision {
    background-image: url("./assets/image/icon05.png");
    background-size: contain;
    background-position: center;
  }
  .vision-box {
    display: flex;
    padding: 202px 0px;
    box-sizing: border-box;
    @media (max-width: 1440px) {
      padding: 50px 18px;
    }
  }
  .vision-left {
    flex: 1;
    padding-right: 40px;
  }
  .vision-left-p {
    font-size: 50px;
    padding-bottom: 10px;
    padding-top: 50px;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .vision-left-text {
    font-size: 22px;
    margin-top: 62px;
    color: #a5a5a5;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .vision-right img {
    width: 607px;
    border-radius: 30px;
  }

  .encouragement {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 943px;
    background-image: url("./assets/image/icon07.png");
    background-size: cover;
    background-position: center;
  }
  .encouragement-box {
    align-items: center;
    display: flex;
    padding: 202px 0px;
    box-sizing: border-box;
    @media (max-width: 1440px) {
      padding: 50px 18px;
    }
  }
  .encouragement-left {
    flex: 1;
    padding-right: 40px;
  }
  .encouragement-left-p {
    font-size: 50px;
    padding-bottom: 10px;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .encouragement-left-text {
    font-size: 22px;
    margin-top: 34px;
    color: #a5a5a5;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .encouragement-right img {
    width: 607px;
    border-radius: 30px;
  }

  .core {
    background: #000;
  }
  .core-bg {
    /* height: 1080px; */
    background-image: url("./assets/image/icon07.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 1440px) {
      // background-size: cover;
    }
  }
  .core-box {
    flex-wrap: wrap;
    justify-content: center;
  }
  .core-p {
    text-align: center;
    padding-top: 100px;
    margin-bottom: 100px;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .core-li {
    width: 261.539px;
    height: 339.294px;
    text-align: center;
    margin-right: 102px;
    margin-bottom: 60px;
    border-radius: 40px;
    background: #131d29;
    box-shadow: 0px 13px 6.7px 0px rgba(0, 0, 0, 0.25);
  }
  .core-li:nth-child(3n) {
    margin-right: 0px;
  }
  .core-li-img {
    width: 178px;
    height: 178px;
    border-radius: 0px 0px 40px 40px;
  }
  .core-li p {
    color: #fff;
    font-size: 24px;
    margin: 10px 0px 10px 0px;
    line-height: 24px;
  }
  .core-li-text {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-size: 18px;
    padding: 0px 25px 14px 25px;
    line-height: 18px;
  }
  .core .coreContent {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 100px;
    position: relative;
    @media (max-width: 1023px) {
      padding-bottom: 50px;
    }
  }
  .core .coreContent .coreleft {
    width: 746px;
    // margin-bottom: -100px;
    margin-right: -140px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    @media (max-width: 1023px) {
      position: unset;
    }
  }
  .core .coreContent .coreright {
    width: 100%;
    max-width: 606px;
    z-index: 1;

    .coreright-items {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .core .coreContent .coreleft img {
    width: 100%;
  }
  .core .coreContent .coreright-items {
    color: #fff;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    > div {
      margin-top: 10px;
      color: rgba(255, 255, 255, 0.6);
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .innovation {
    height: 934px;
    background-image: url("./assets/image/icon05.png");
    background-size: contain;
    background-position: center;
  }
  .innovation-box {
    display: flex;
    padding: 202px 0px;
    box-sizing: border-box;
  }
  .innovation-left {
    padding-right: 60px;
    padding-left: 75px;
    padding-top: 80px;
  }
  .innovation-left-p {
    font-size: 50px;
    padding-bottom: 10px;
    padding-top: 50px;
  }
  .innovation-left-text {
    font-size: 22px;
    margin-top: 50px;
  }
  .innovation-right img {
    width: 540px;
    height: 540px;
    border-radius: 40px;
  }
  .technology {
    background-image: url("./assets/image/icon05.png");
    background-size: contain;
    background-position: center;
  }
  .technology-box {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 202px 0px;
    @media (max-width: 1440px) {
      display: block;
      padding: 50px 18px;
    }
  }

  .technology-left img {
    width: 100%;
    border-radius: 40px;
  }
  .technology-right {
    max-width: 548px;
    margin-left: 45px;
    z-index: 1;
    @media (max-width: 1440px) {
      max-width: 100%;
      margin-left: 0px;
    }
  }
  .technology-right-p {
    font-size: 50px;
    margin-bottom: 62px;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: left;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @media (max-width: 1440px) {
      text-align: center;
      margin: 60px 0px;
    }
  }
  .technology-right-text {
    font-size: 22px;
    margin-top: 20px;
    color: #a5a5a5;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }

  .conclusion {
    background-image: url("./assets/image/icon05.png");
    background-size: contain;
    background-position: center;
  }
  .conclusion-box {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 202px 0px;
    @media (max-width: 1440px) {
      padding: 50px 18px;
    }
  }
  .conclusion-left {
    margin-top: -110px;
  }
  .conclusion-left img {
    width: 100%;
    border-radius: 40px;
  }
  .conclusion-right {
    position: relative;
    margin-left: 45px;
    z-index: 1;
  }
  .conclusion-right-p {
    font-size: 50px;
    margin-bottom: 62px;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    color: #fff;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .conclusion-right-text {
    font-size: 22px;
    margin-top: 20px;
    color: #a5a5a5;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .advantages {
    background: #000;
    padding-bottom: 230px;
    @media (max-width: 1440px) {
      padding-bottom: 50px;
    }
  }

  .advantages-bg img {
    width: 100%;
  }
  .advantages-box {
    margin-top: 62px;
    max-width: 1200px;
    justify-content: center;
    color: #a5a5a5;
    text-align: center;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .advantages-p {
    color: #fff;
    font-size: 50px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .advantages-li {
    margin-right: 54px;
  }
  .advantages-li:last-child {
    margin-right: 0px;
  }
  .advantages-li:nth-child(even) {
    margin-top: 135px;
    animation: top_buttom_float 2s ease-in-out infinite alternate;
  }
  .advantages-li:nth-child(odd) {
    animation: top_buttom_float 2.4s ease-in-out infinite alternate;
  }
  .advantages-li-img {
    width: 184px;
    height: 184px;
    border-radius: 115.5px;
  }
  .advantages-li p {
    color: #fff;
    text-align: center;
    font-size: 26px;
    margin-top: 30px;
  }
  .advantages-linear {
    height: 264px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 0, 0, 0),
      #131d29,
      #131d29
    );
  }
  .dragonRealmNFT {
    background: #131d29;
    padding: 190px 0px 80px 0px;
  }
  .dragonRealmNFT-box {
    display: flex;
  }
  .dragonRealmNFT-left {
    width: 574px;
    height: 949px;
    text-align: center;
    position: relative;
  }
  .dragonRealmNFT-left .box-ushadow {
    width: 574px;
    height: 342px;
    position: absolute;
    z-index: 6;
    background: linear-gradient(180deg, #131d29 0%, rgba(19, 29, 41, 0) 100%);
  }
  .dragonRealmNFT-left .box-pshadow {
    width: 574px;
    height: 340px;
    position: relative;
    top: -298px;
    z-index: 6;
    background: linear-gradient(0deg, #131d29 0%, rgba(19, 29, 41, 0) 100%);
  }
  .dragonRealmNFT-left .box .box-li {
    height: 302px;
    position: relative;
  }
  .dragonRealmNFT-left .box-li-img {
    width: 428px;
    height: 342px;
    border-radius: 40px;
  }
  .dragonRealmNFT-left .box-li-img_active {
    width: 574px;
    height: 574px;
    top: -166px;
    position: relative;
    z-index: 11;
  }
  .stepper {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 36%);
    bottom: 0px;
    z-index: 8;
  }
  .stepper svg {
    cursor: pointer;
  }
  .dragonRealmNFT-left .stepper-box {
    color: #fff;
    padding: 19px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #fff;
    display: flex;
    margin: 0px 20px;
    font-size: 30px;
  }
  .dragonRealmNFT-left .stepper-box .stepper-box-num {
    width: 142px;
  }
  .dragonRealmNFT-left .stepper-box .stepper-box-dot {
    width: 29px;
    height: 29px;
    margin: 0px 8px;
    background: #9c9c9c;
    border-radius: 50%;
  }
  .dragonRealmNFT-right {
    width: 500px;
    color: #fff;
    padding: 174px 0px 0px 125px;
  }
  .dragonRealmNFT-right .nft {
    font-size: 60px;
    padding-bottom: 10px;
  }
  .dragonRealmNFT-right .text {
    font-size: 30px;
    margin-top: 50px;
  }
  .dragonRealmNFT-right .buy {
    width: 304px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    border-radius: 10px;
    box-sizing: border-box;
    background: #fff;
    color: #000;
    font-size: 30px;
    margin-top: 60px;
    cursor: pointer;
  }
  .dragonRealmNFT-right .buy svg {
    margin-left: 20px;
  }
  .dragonRealmNFT-right .footer {
    position: relative;
  }
  .dragonRealmNFT-right .footer img {
    width: 161px;
    height: 133px;
    position: absolute;
    right: -54px;
    bottom: -26px;
  }
  .community {
    text-align: center;
    position: relative;
    background: #131d29;
    padding-bottom: 80px;
  }
  .community .community-title {
    color: #fff;
    font-size: 50px;
    padding: 180px 0px 66px 0px;
  }
  .community .community-text {
    color: #fff;
    font-size: 22px;
  }
  .community .community-box {
    width: 839px;
    margin: 0 auto;
  }
  .community .community-mask {
    height: 645px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, #131d29 0%, rgba(19, 29, 41, 0) 100%);
  }
  .tokenmics {
    background: #000;
    padding: 160px 0px 68px 0px;
    @media (max-width: 1440px) {
      padding: 50px 18px;
    }
  }
  .tokenmics-title {
    color: #fff;
    text-align: center;
    font-size: 50px;
    padding-bottom: 60px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .tokenmics-bg {
    height: 719px;
    position: relative;
    background-image: url("./assets/image/icon33.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .tokenmics-address {
    margin-top: 40px;
    border-radius: 30px;
    border: 1px solid #fff;
    background: #262626;
    box-shadow: 0px 13px 6.7px 0px rgba(0, 0, 0, 0.25);
  }
  .tokenmics-box-supply {
    margin-top: 58px;
    display: flex;
    align-items: baseline;
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    color: #fff;
    text-align: center;
    font-size: 30px;
    padding: 34px;
    border-radius: 30px;
    border: 1px solid #fff;
    background: #262626;
    backdrop-filter: blur(5px);
  }
  .tokenmics-box-supply .left {
    flex: 1;
    text-align: left;
    font-size: 30px;
  }
  .tokenmics-box-supply .right {
    flex: 1;
    text-align: right;
    font-size: 50px;
  }
  .development {
    background: #010816;
    background-image: url("./assets/image/icon28.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .development-bg {
    width: 100%;
    max-width: 1236px;
    padding: 0px 18px;
    height: 1166px;
    margin: 0 auto;
  }
  .development p {
    margin: 0;
    padding: 41px 0px 0px 0px;
    color: #fff;
    font-size: 50px;
    text-align: center;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 18px #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
  }
  .development-box {
    margin-bottom: 66px;
  }

  .development-box-top {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
  }
  .development-box-top-mid,
  .development-box-top2 {
    .li {
      &:nth-child(1) {
        width: 346px !important;
      }
      &:nth-child(2) {
        width: 324px !important;
      }
    }
  }
  .development-box-top-top {
    margin-top: 66px;
    justify-content: center;
  }
  .development-box-top .li {
    width: 346px;
  }
  .development-box-top-top .li {
    width: 100%;
  }
  .development-box-top-top .li div {
    display: flex;
    justify-content: center;
  }
  .development-box-top-top .li div:nth-child(3) {
    margin-top: 10px;
  }
  .development-box-top .li:nth-child(3) {
    width: 258px;
    position: relative;
    left: -142px;
  }
  .development-box-top .li-title {
    color: #fff;
    font-size: 22px;
    padding-bottom: 0px;
    line-height: 28px;
    margin-bottom: 17px;
  }
  .development-box-top .li-title-text {
    color: #fff;
    font-size: 18x;
    margin-top: 10px;
  }
  .development-box-top2 {
    margin-top: 215px;
  }
  .development-box-top2 .li:nth-child(1) {
    width: 360px;
  }
  .development-box-top2 .li:nth-child(2) {
    width: 312px;
  }
  .development-box-top2 .li:nth-child(3) {
    width: 225px;
  }

  .footers {
    background: #232323;
  }
  .footers-box {
    padding: 28px 0px;
    position: relative;
    display: flex;
    @media (max-width: 1440px) {
      padding: 18px;
    }
  }
  .footers-p {
    flex: 1;
    // padding-bottom: 38px;
    margin: 0px;
    color: #a5a5a5;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    > div {
      color: #a5a5a5;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .footers-orter {
    display: flex;
    align-items: center;
  }
  .footers-p .gmail {
    font-size: 20px;
    padding-top: 20px;
  }
  .footers svg {
    width: 28px;
    height: 28px;
    margin-right: 16px;
    cursor: pointer;
  }
  .footers .icon24 {
    width: 91px;
    height: 65px;
    position: absolute;
    right: 135px;
    bottom: 0px;
  }
  .footers .icon27 {
    width: 109px;
    height: 143px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
.dashboard {
  background: #eef1fa;
  background-image: url("./assets/image/dashboard/bg.png");
  background-size: contain;
  background-position: center;
}
.dashboard-box {
  display: flex;
  font-family: "Inter";
}
.dashboard-box-left {
  width: 310px;
  height: calc(100vh - 22px);
  box-sizing: border-box;
  position: fixed;
  left: 22px;
  top: 22px;
}
.dashboard-box-left .left-bg {
  height: calc(100vh - 44px);
  border-radius: 33px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.08);
}
.dashboard-box-left-logo {
  width: 76px;
  height: 80px;
  display: block;
  margin: 30px auto 40px auto;
}
.dashboard-box-left .li {
  cursor: pointer;
  font-size: 16px;
  color: #a3a3a3;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin: 0px 29px;
}
.dashboard-box-left .li svg {
  margin-right: 16px;
}
.dashboard-box-left .li-active {
  color: #ab54db;
  border-radius: 10px;
  background: #ab54db26;
}
.dashboard-box-right {
  margin-left: 356px;
}
.dashboard-box-right .header {
  display: flex;
  padding-top: 40px;
}
.dashboard-box-right .header-left {
  flex: 1;
}
.dashboard-box-right .header-left-title {
  color: #202020;
  font-size: 24px;
  font-weight: 700;
}
.dashboard-box-right .header-left-sub {
  color: #202020;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}
.dashboard-box-right .header-right {
  height: 46px;
  color: #202020;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  padding: 9px 25px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #202020;
}

.dashboard-box-right .main-left-box {
  padding: 30px;
  box-sizing: border-box;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}
.dashboard-box-right .main-left-top {
  position: relative;
}
.dashboard-box-right .main-left-staking {
  color: #202020;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
}
.dashboard-box-right .main-left-join {
  width: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #8545a7;
  padding: 9px 0px;
  box-sizing: border-box;
  margin-bottom: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.dashboard-box-right .main-left-join svg {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}
.dashboard-box-right .main-left-top-icon {
  width: 142px;
  height: 143px;
  position: absolute;
  top: -10px;
  right: -10px;
}
.dashboard-box-right .main-left-bottom {
  display: flex;
}
.dashboard-box-right .main-left-bottom-box {
  flex: 1;
  padding: 16px 18px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #eef1fa;
}
.dashboard-box-right .main-left-bottom-box:nth-child(1) {
  margin-right: 15px;
}
.dashboard-box-right .main-left-bottom-box:nth-child(2) {
  margin-right: 15px;
}
.dashboard-box-right .main-left-bottom-box .total {
  display: flex;
  margin-bottom: 16px;
}
.dashboard-box-right .main-left-bottom-box .total-num {
  flex: 1;
  color: #202020;
  font-size: 26px;
  font-weight: 700;
}
.dashboard-box-right .main-left-bottom-box .total-right {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  border-radius: 4.848px;
  background: #fff;
}
.dashboard-box-right .main-left-bottom-box .sall {
  display: flex;
  align-items: center;
  color: #202020;
  font-size: 16px;
  font-weight: 700;
}
.dashboard-box-right .main-left-bottom-box .sall span {
  flex: 1;
  text-align: right;
  font-size: 12px;
}
.main-left .revenue {
  display: flex;
  margin-top: 17px;
}
.main-left .revenue .revenue-box:nth-child(1) {
  margin-right: 8px;
}
.main-left .revenue .revenue-box:nth-child(2) {
  margin-left: 8px;
}
.main-left .revenue-box {
  flex: 1;
  border-radius: 24px;
  background: #fff;
  text-align: center;
  padding: 40px 40px 62px 40px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}
.main-left .revenue-title {
  color: #202020;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}
.main-left .revenue-to {
  color: #fff;
  border-radius: 10px;
  background: #8545a7;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 14px 0px;
  cursor: pointer;
}
.main-left .revenue-p {
  color: #202020;
  font-size: 16px;
  font-weight: 500;
}
.main-left .revenue-to-disabled {
  border-radius: 10px;
  background: rgba(133, 69, 167, 0.5);
}
.main-left .revenue-svg {
  width: 88px;
  height: 88px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.dashboard-box-right .main {
  display: flex;
  padding-top: 60px;
}
.dashboard-box-right .main-left {
  flex: 1;
  margin-right: 10px;
}
.dashboard-box-right .main-right {
  flex: 1;
  margin-left: 10px;
}
.dashboard-box-right .main-right-box {
  padding: 30px;
  border-radius: 24px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}
.dashboard-box-right .main-right-box:nth-child(2) {
  height: 258px;
  margin-top: 17px;
}
.dashboard-box-right .main-right-box .header {
  padding-top: 0px;
}
.dashboard-box-right .main-right-box .charts {
  height: 220px;
}
.dashboard-box-right .main-right-box .charts .chart-container {
  /* height: 187px !important; */
}
.dashboard-box-right .main-right-box-header {
  display: flex;
}
.dashboard-box-right .main-right-box-header-left {
  flex: 1;
  color: #202020;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.dashboard-box-right .main-right-box-header-left .total-right {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  color: #202020;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4.848px;
  border: 1px solid #000;
  margin-left: 10px;
}
.dashboard-box-right .main-right-box-header-left .total-right svg {
  width: 17px;
  height: 17px;
}
.dashboard-box-right .main-right-box-header-right {
  display: flex;
  border: none;
}
.dashboard-box-right .header-right-box {
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 8px;
  border-radius: 6px;
  font-size: 14px;
  margin-left: 8px;
  border: 1px solid #3b3741;
}
.dashboard-box-right .header-right-box .header-right-box_on {
  color: #8545a7;
}
.dashboard-box-right .header-right-box span {
  padding: 0 6px;
}
.dashboard-box-right .header-right-box img:last-child {
  margin-right: 0px;
}
.dashboard-box-right .header-right-box img {
  width: 18px;
  height: 18px;
  margin: 0 8px;
}
.dashboard-transaction {
  margin-top: 30px;
  padding: 32px 0px;
  border-radius: 24px;
  background: #fff;
}
.dashboard-transaction-top {
  display: flex;
  color: rgba(32, 32, 32, 0.8);
  font-size: 16px;
  font-weight: 400;
  padding: 0px 50px;
  border-bottom: 1px solid #eceaf3;
}
.dashboard-transaction-top .item {
  flex: 1;
  padding: 0px 0px 22px 0px;
}
.dashboard-transaction-top .created {
  width: 80px;
  text-align: right;
}
.dashboard-transaction-no {
  color: #202020;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 124px 0px 175px 0px;
}
.dashboard-transaction-main {
  display: flex;
  color: #202020;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 50px;
}
.dashboard-transaction-main .item {
  flex: 1;
  padding: 22px 0px;
}
.dashboard-transaction-main {
  border-bottom: 1px solid #eceaf3;
}
.dashboard-transaction-main:nth-child(even) {
  background: #fcfcfc;
}
.dashboard-transaction-main .created {
  width: 80px;
  text-align: right;
}
.dashboard-transaction-main .statu1 {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 26px 8px 16px;
  color: #00a389;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: rgba(46, 214, 163, 0.15);
}
.dashboard-transaction-main .statu2 {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 26px 8px 16px;
  color: #ffbb54;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: rgba(255, 187, 84, 0.15);
}
.dashboard-transaction-main .statu3 {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 26px 8px 16px;
  color: #ff5b5b;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: rgba(255, 91, 91, 0.15);
}
.dashboard-transaction-main svg {
  margin-right: 14px;
}
/* 分页组件 */
.pagination {
  padding: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000099;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #fff;
  margin: 0px 4px;
  cursor: pointer;
}
.pagination-left {
  margin-right: 4px;
  cursor: pointer;
}
.pagination-right {
  margin-left: 4px;
  cursor: pointer;
}
.pagination .active {
  color: #000000;
  border-radius: 3px;
  background: #d6d6d6;
  border: 1px solid #d6d6d6;
}
.Modal {
}
.Modal,
.Modal .ant-modal-content {
  background: #000;
  padding: 0px;
  border-radius: 30px;

  .ant-modal-body {
    overflow: hidden;
    border: 1px solid #fff;
    border-radius: 30px;
    position: relative;
    padding: 26px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modal-close {
      position: absolute;
      top: 15px;
      right: 20px;
    }
    .modal-title {
      color: #fff;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 19px; /* 95% */
    }
    .modal-content {
      width: 100%;
      .progressBox {
        margin-top: 26px;
        > div {
          &:first-child {
            margin-bottom: 20px;
          }
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #a5a5a5;
          text-align: center;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          > span {
            display: flex;
            align-items: center;
            color: #fff;
            text-align: center;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            > span {
              color: #6f6f6f;
            }
          }
        }
        .progressDiv {
          margin: 10px auto 20px;
          height: 14px;
          border-radius: 30px;
          background: #353535;
          > div {
            height: 14px;
            border-radius: 30px;
            background: #fbc60c;
          }
        }
        .buyBox {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: end;
          margin: 26px auto 6px;
          .buyBtn {
            cursor: pointer;
            width: 100%;
            padding: 18px 90px;
            color: #000;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            border-radius: 10px;
            border: 2px solid #000;
            background: #fbc60c;
          }
          .noBuyBtn {
            opacity: 0.5;
          }
          .History {
            margin-top: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #6f6f6f;
            text-align: center;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
          }
        }
        .deviderLine {
          margin: 26px auto;
          background: #474747;
          height: 1px;
        }
        .invitationLink {
          display: block;
          .invitationLink_top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #a5a5a5;
            text-align: center;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .History {
              color: #6f6f6f;
              text-align: center;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
          .invitationLink_bottom {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 30px;
            background: #262626;
            padding: 17px 30px;
            color: #fff;
            word-break: break-all;
            text-align: flex-start;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
        .progressText {
          display: block;
          text-align: left;
          color: #fff;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          .p {
            display: flex;
            align-items: flex-start;

            color: #a5a5a5;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            white-space: wrap;
            margin-bottom: 4px;
            &:nth-child(1) {
              margin-top: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            > div {
              width: 14px;
              height: 14px;
              margin-right: 3px;
            }
          }
        }
        .historyItem {
          margin-bottom: 20px;
          > span {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > svg {
              margin-left: 10px;
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .success-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 54px 0px 70px;
      color: #a5a5a5;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 19px; /* 95% */
      > svg {
        margin-bottom: 20px;
      }
    }

    .history_title {
      margin: 26px auto 20px;
      > div {
        color: #5f5f5f;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &:first-child {
          justify-content: flex-start;
          align-items: center;
        }
        &:last-child {
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
    .scroll_history_content {
      overflow-y: auto;
      max-height: 232px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgb(109, 176, 203);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
    }
    .nodata {
      margin: auto;
      width: 176px;
      height: 130px;
      margin: 50px auto;
      > img {
        width: 100%;
      }
    }
    .history_content {
      margin-bottom: 20px;
      > div {
        &:nth-child(2) {
          text-align: left;
          // padding-left: 15px;
        }
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        flex: 1;
        color: #a5a5a5;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:first-child {
          justify-content: flex-start;
          align-items: center;
        }
        &:last-child {
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
    @media (max-width: 650px) {
      border-radius: 20px;
      padding: 20px 16px;
      .modal-close {
        top: 18px;
        right: 14px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .modal-title {
        color: #fff;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 19px; /* 105.556% */
      }
      .modal-content {
        .progressBox {
          margin-top: 20px;
          > div {
            color: #a5a5a5;
            text-align: center;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            > span {
              font-size: 14px;
            }
          }
          .progressDiv {
            height: 12px;
            margin: 10px auto 14px;
            > div {
              height: 12px;
            }
          }
          .buyBtn {
            border-radius: 10px;
            color: #000;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            margin: 20px auto 6px;
            padding: 13.2px 61.2px;
          }
          .History {
            color: #6f6f6f;
            text-align: center;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .deviderLine {
            margin: 20px auto;
          }
          .progressText {
            color: #fff;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .p {
              color: #a5a5a5;
              font-family: "Inter";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 2px;
              &:nth-child(1) {
              }
            }
          }
          .historyItem {
            margin-bottom: 16px;
            > span {
              > svg {
                width: 16px;
                height: 16px;
                margin-left: 2px;
              }
            }
          }
        }
        .progressBox .buyBox,
        .Modal
          .ant-modal-content
          .ant-modal-body
          .modal-content
          .progressBox
          .buyBox {
          margin-top: 0px;
        }
        .progressBox > div:first-child,
        .Modal
          .ant-modal-content
          .ant-modal-body
          .modal-content
          .progressBox
          > div:first-child {
          margin-bottom: 14px;
        }
        .progressBox .buyBox .buyBtn,
        .Modal
          .ant-modal-content
          .ant-modal-body
          .modal-content
          .progressBox
          .buyBox
          .buyBtn {
          color: #000;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          padding: 12px;
        }
        .progressBox .invitationLink .invitationLink_bottom,
        .Modal
          .ant-modal-content
          .ant-modal-body
          .modal-content
          .progressBox
          .invitationLink
          .invitationLink_bottom {
          color: #fff;
          text-align: center;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 10px;
          background: #262626;
          padding: 13px 20px;
        }
        .progressBox .buyBox .History,
        .Modal
          .ant-modal-content
          .ant-modal-body
          .modal-content
          .progressBox
          .buyBox
          .History {
          font-size: 14px;
        }
        .progressBox .invitationLink .invitationLink_top,
        .Modal
          .ant-modal-content
          .ant-modal-body
          .modal-content
          .progressBox
          .invitationLink
          .invitationLink_top {
          font-size: 14px;
          .History {
            font-size: 14px;
          }
        }
      }
      .success-modal-content {
        padding: 28px 0px;
        > svg {
          width: 50px;
          height: 50px;
          flex-shrink: 0;
          margin-bottom: 14px;
        }
        color: #a5a5a5;
        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px; /* 118.75% */
      }
    }
  }
}
